/** @jsxImportSource @emotion/react */

import { Box, Button } from "@mui/material";
import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Title = () => {
  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: { xs: "300px", md: "500px" },
        marginLeft: { xs: "24px", md: "0px" },
        transform: {
          lg: "translateX(80px)",
          md: "translateX(40px)",
          xs: "translateX(0px)",
        },
        zIndex: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "36px",
        }}
      >
        <div
          css={{
            width: "12px",
            backgroundColor: "#FF1472",
            borderRadius: "10px",
            marginRight: "12px",
          }}
        />
        <BonusxTypography variant="title" css={{ maxWidth: "500px" }}>
          {l_bonusx("reviews.title")}
        </BonusxTypography>
      </Box>
      <Box sx={{ zIndex: 3, position: "relative" }}>
        <Button
          variant="contained"
          href="https://it.trustpilot.com/review/bonusx.it"
          target={"_blank"}
          sx={{
            display: { xs: "none", md: "inline-flex" },
            position: "relative",
            zIndex: 3,
            backgroundColor: "#1A59E7",
            color: "#fff",
            textTransform: "none",
            padding: "12px 52px",
            borderRadius: "12px",
            ":hover": {
              backgroundColor: "#2666F5",
            },
            ":active": { backgroundColor: "#1A59E7" },
          }}
        >
          {l_bonusx("reviews.button")}
        </Button>
      </Box>
    </Box>
  );
};

export default Title;
