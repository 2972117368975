import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        marginTop: { xs: "64px", md: "250px" },
        marginBottom: { xs: 0, md: "0px" },
        padding: { xs: "24px", md: "12px" },
        position: "relative",
        zIndex: 2,
      }}
    >
      <BonusxTypography
        variant="title"
        sx={{
          maxWidth: "1100px",
          margin: "auto",
          marginBottom: { xs: "20px", md: "40px" },
        }}
      >
        {l_bonusx("burocracy.title1")}
        <BonusxTypography variant="span" color="magenta">
          {l_bonusx("burocracy.title2Bold")}
        </BonusxTypography>{" "}
        {l_bonusx("burocracy.title3")}
      </BonusxTypography>
      <BonusxTypography variant="subtitle" sx={{ margin: "auto" }}>
        {l_bonusx("burocracy.subtitle1")}
        <BonusxTypography variant="span" sx={{ fontWeight: 700 }}>
          {l_bonusx("burocracy.subtitle2Bold")}
        </BonusxTypography>{" "}
        {l_bonusx("burocracy.subtitle3")}
        <BonusxTypography variant="span" sx={{ fontWeight: 700 }}>
          {l_bonusx("burocracy.subtitle4Bold")}
        </BonusxTypography>{" "}
        <BonusxTypography variant="span">
          {l_bonusx("burocracy.subtitle5")}
          {isOverMd && (
            <StaticImage alt="" src="../../images/computerIcon.png" />
          )}
          {!isOverMd && (
            <StaticImage alt="" src="../../images/computerIconSmall.png" />
          )}
        </BonusxTypography>
        {/* 👩‍💻 */}
      </BonusxTypography>
    </Box>
  );
};

export default Title;
