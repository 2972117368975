/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const CentralPictureBottomRight = () => {
  return (
    <div
      css={{
        position: "absolute",
        zIndex: 2,
        bottom: "0%",
        right: "0%",
      }}
    >
      <div
        css={{
          position: "absolute",
          width: "220px",
          aspectRatio: "1.6 / 1",
          bottom: 0,
          right: "-30%",
          borderRadius: "20px",
          backgroundColor: "#1A59E733",
        }}
      ></div>
      <Box sx={{ marginRight: "10px" }}>
        <StaticImage
          src={"../../images/illustrationBackend.png"}
          css={{ width: "100%" }}
          alt=""
        />
      </Box>
    </div>
  );
};

export default CentralPictureBottomRight;
