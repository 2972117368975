/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import CardsSection from "./EasyBurocracySection/CardsSection";
import DecorationSection from "./EasyBurocracySection/DecorationSection";
import ShapedBackground from "./EasyBurocracySection/ShapedBackground";
import Title from "./EasyBurocracySection/Title";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";

const EasyBurocracySection = () => {
	const theme = useTheme();
	const isUnderMd = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box
			sx={{
				position: "relative",
				overflow: "hidden",
				textAlign: "center",
				paddingBottom: "100px",
			}}
		>
			<ShapedBackground />
			<Title />
			<DecorationSection />
			<CardsSection />
			{isUnderMd && (
				<MabiParallax
					id="burocracySectDecorationSmall"
					tweens={[
						{
							selector: ".modulesIllustration2Small",
							from: {
								y: 50,
								x: 50,
								rotate: "30deg",
							},
							to: {
								y: 0,
								x: 0,
								rotate: "0deg",
							},
						},
					]}
				>
					<Box
						sx={{
							position: "absolute",
							display: "block",
							bottom: "6%",
							right: 0,
							width: "178px",
							zIndex: 2,
						}}
						className="modulesIllustration2Small"
					>
						<StaticImage
							src={"../images/modulesIllustration_2.png"}
							alt=""
						/>
					</Box>
				</MabiParallax>
			)}
		</Box>
	);
};
export default EasyBurocracySection;
