/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Description = () => {
  return (
    <Box
      sx={{
        position: { md: "absolute", xs: "static" },
        maxWidth: { md: "45%", xs: "100%" },
        padding: { md: "24px", xs: "40px 0px" },
        top: 0,
        right: "calc(12% - 24px)",
      }}
    >
      <Box
        sx={{
          width: "50%",
          height: "6px",
          backgroundColor: "#1A59E7",
          borderRadius: "10px",
          marginBottom: { lg: "24px", xs: "12px" },
          marginTop: { lg: "8px", xs: "-8px" },
          display: { md: "block", xs: "none" },
        }}
      ></Box>
      <Box sx={{ marginRight: { lg: "20px", xs: 0 } }}>
        <BonusxTypography variant="description">
          {l_bonusx("frontend.description")}
        </BonusxTypography>
      </Box>
    </Box>
  );
};

export default Description;
