/** @jsxImportSource @emotion/react */

import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Description = () => {
  return (
    <div css={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}>
      <BonusxTypography>{l_bonusx("backend.description")}</BonusxTypography>
    </div>
  );
};

export default Description;
