/** @jsxImportSource @emotion/react */

import { Typography, TypographyProps } from "@mui/material";
import { ress } from "../../../../../components/shared/mtext";

type BonusxTypographyProps = {
  variant?: "header" | "title" | "subtitle" | "description" | "span";
  color?: "black" | "blue" | "magenta" | "green";
  className?: string;
} & Omit<TypographyProps, "variant" | "color">;

const BonusxTypography: React.FC<BonusxTypographyProps> = ({
  variant,
  color,
  sx,
  ...props
}) => {
  return (
    <Typography
      component={variant === "span" ? "span" : "p"}
      sx={{
        ...(variant === "header"
          ? {
              fontSize: { xs: "32px", md: "70px" },
              lineHeight: { xs: "30px", md: "96px" },
              fontWeight: 700,
            }
          : variant === "title"
          ? {
              fontSize: { xs: "40px", md: "64px" },
              lineHeight: { xs: "36px", md: "68px" },
              fontWeight: { xs: 600, md: 700 },
            }
          : variant === "subtitle"
          ? {
              fontSize: { xs: "14px", md: "24px" },
              lineHeight: { xs: "18px", md: "25px" },
              fontWeight: 400,
            }
          : variant === "description"
          ? {
              fontSize: { xs: "14px", md: "16px" },
              lineHeight: { xs: "18px", md: "20px" },
              fontWeight: 400,
            }
          : variant === "span"
          ? {
              fontSize: "inherit",
              lineHeight: "inherit",
              fontWeight: "inherit",
            }
          : {}),
        ...(color === "black"
          ? { color: "#000000" }
          : color === "magenta"
          ? { color: "#FF1472" }
          : color === "blue"
          ? { color: "#1A59E7" }
          : color === "green"
          ? {
              color: "#00DA81",
            }
          : { color: "#000000" }),
        ...sx,
      }}
      {...props}
    />
  );
};
export default BonusxTypography;
