/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import ConcentricCircles from "../ConcentricCircles";

const BackgroundPictureSmall = () => {
  return (
    <Box
      sx={{
        display: { md: "none", xs: "block" },
        position: "absolute",
        width: "100%",
        maxWidth: "500px",
        minWidth: "400px",
      }}
    >
      <div
        css={{
          position: "absolute",
          top: "-10%",
          left: "8%",
          width: "17%",
          aspectRatio: "1/1",
          borderRadius: "8px",
          backgroundColor: "#FF14721A",
        }}
        className="backendSmallModules1"
      >
        <div
          css={{
            position: "absolute",
            top: "10px",
            left: "10px",
          }}
        >
          <StaticImage
            src={"../../images/modulesIllustration_1.png"}
            alt=""
            css={{ top: 0, left: "20px" }}
          />
        </div>
      </div>
      <Box
        sx={{
          position: "absolute",
          top: 80,
          left: 100,
          display: { sm: "block", xs: "none" },
        }}
      >
        <StaticImage src={"../../images/blueSphere.svg"} alt={""} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          maxWidth: "30px",
          top: "-12%",
          right: "-5%",
          display: { sm: "block", xs: "none" },
        }}
      >
        <StaticImage src={"../../images/magentaSphere.svg"} alt="" css={{}} />
      </Box>

      <div
        css={{
          position: "absolute",
          zIndex: 2,
          top: "-5%",
          right: "10%",
        }}
      >
        <StaticImage
          src={"../../images/avatarSmallWithProgress.png"}
          alt=""
          css={{}}
        />
      </div>
      <ConcentricCircles className="backendCirclesSmall" />

      <Box
        sx={{
          position: "absolute",
          bottom: "-5%",
          left: 20,
        }}
        className="backendSpheresGroup"
      >
        <StaticImage src={"../../images/spheresGroup.svg"} alt={""} />
      </Box>
      <div
        css={{
          position: "absolute",
          zIndex: 2,
          bottom: "-25%",
          right: "10%",
        }}
        className="backendSmallModules5"
      >
        <StaticImage
          src={"../../images/modulesIllustration_5.png"}
          css={{}}
          alt=""
        />
      </div>
    </Box>
  );
};

export default BackgroundPictureSmall;
