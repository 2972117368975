import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../../components/old/seo_head";
import BonusX from "../../features/case_study/bonusx";
import { l_bonusx } from "../../features/case_study/bonusx/locales";

export default BonusX;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_bonusx("seo.title")}
      description={l_bonusx("seo.description")}
      image="/case_studies_png/bonusx.png"
      headProps={headProps}
    />
  );
};
