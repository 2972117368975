import { Box } from "@mui/material";
import CardsSection from "./NumersCountSection/CardsSection";
import Title from "./NumersCountSection/Title";

const NumbersCountSection = () => {
  return (
    <Box
      sx={{
        paddingTop: { xs: "12px", md: "180px" },
      }}
    >
      <Title />
      <CardsSection />
    </Box>
  );
};
export default NumbersCountSection;
