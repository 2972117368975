function BlueCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__bluecard_a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={3}
        width={21}
        height={18}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18.545c-3.437 0-6.57-2.444-8.43-6.553C5.43 7.884 8.564 5.442 12 5.44c3.437.001 6.57 2.444 8.43 6.552-1.86 4.11-4.993 6.552-8.43 6.553zm-.002 1.5h.004c4.137-.003 7.852-2.902 9.937-7.757a.742.742 0 000-.592c-2.086-4.854-5.8-7.753-9.936-7.756h-.001H12h-.001-.002c-4.135.003-7.85 2.902-9.936 7.756a.751.751 0 000 .592c2.086 4.855 5.8 7.754 9.936 7.757zm-2.41-8.052A2.414 2.414 0 0112 9.581a2.415 2.415 0 012.412 2.412 2.414 2.414 0 01-2.413 2.41 2.413 2.413 0 01-2.41-2.41zm-1.5 0A3.915 3.915 0 0012 15.903a3.915 3.915 0 003.912-3.91 3.917 3.917 0 00-3.913-3.912 3.916 3.916 0 00-3.91 3.912z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__bluecard_a)">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </g>
    </svg>
  );
}

export default BlueCardIcon;
