import { DeepKeyof, language } from "../../../traductions";

const traduction = {
  it: {
    seo: {
      title: "BonusX",
      description:
        "Piattaforma web innovativa per semplificare la burocrazia italiana",
    },
    header: {
      title1: "Abbiamo supportato ",
      title2Bold: "BonusX",
      title3: " nella semplificazione della burocrazia Italiana",
    },
    burocracy: {
      title1: "BonusX ",
      title2Bold: "semplifica",
      title3: "la burocrazia italiana",
      subtitle1: "Un progetto che possa aiutare i #cittadini in modo ",
      subtitle2Bold: "semplice",
      subtitle3: " e ",
      subtitle4Bold: "veloce ",
      subtitle5: " ",
      card1Title: "ISEE",
      card1Description: "Richiesta del proprio ISEE annuo online e veloce.",
      card2Title: "730",
      card2Description:
        "È possibile fare il modello 730, scoprire agevolazioni e aumentare il tuo risparmio fiscale.",
      card3Title: "Bonus pubblici",
      card3Description:
        "Si possono richiedere bonus e servizi di cui si ha bisogno online, senza file e con il supporto di professionisti qualificati.",
    },
    numbers: {
      title: "Quando si tratta di burocrazia i numeri contano",
      subtitle: "Questi sono alcuni dei risultati di BonusX a settembre 2022",
      card1Description: "Utenti",
      card2Description: "In bonus erogati",
      card3Description: "Visitatori mensili",
    },
    reviews: {
      title: "Cosa dicono di BonusX",
      button: "Leggi su Trustpilot",
      card1Review:
        "Sito semplice e facile, ho fatto richiesta del bonus in meno di 5 minuti. È una salvezza per chi come me non può andare negli uffici",
      card2Review:
        "Non è la prima volta che utilizzo questo servizio e anche questa volta posso confermare la mia piena soddisfazione. Pratica finalizzata velocemente e senza intoppi, tutto online senza perdite di tempo. Grazie mille BonusX ottimo lavoro!",
      card3Review:
        "Direi ottima, come se mi fossi recato in ufficio, anzi meglio",
    },
    frontend: {
      title1: "Sviluppo ",
      title2Bold: "Frontend",
      title3: " ",
      title3alt: " ",
      title4: " ",
      description:
        "Abbiamo sviluppato una piattaforma in React che fosse utile, semplice e intuitiva, fruibile da web come da mobile.",
    },
    backend: {
      title1: "Sviluppo ",
      title2Bold: "Backend",
      title3: " ",
      description:
        "L'algoritmo di BonusX consiglia i bonus più adatti ai propri utenti: attraverso la compilazione del questionario guidato, ogni utente riceve comunicazioni su come sfruttare al meglio i propri bonus! Abbiamo costruito un’infrastruttura solida con Node.js in Typescript, che permette di gestire centinaia di pratiche ogni giorno e i rapporti con CAF di tutta Italia.",
    },
  },
  en: {
    seo: {
      title: "BonusX",
      description:
        "Innovative web platform to simplify the Italian bureaucracy",
    },
    header: {
      title1: "We supported ",
      title2Bold: "BonusX",
      title3: " in making Italian bureaucracy easy",
    },
    burocracy: {
      title1: "BonusX ",
      title2Bold: "simplifies",
      title3: "Italian bureaucracy",
      subtitle1: "A project that can help #citizens in a ",
      subtitle2Bold: "simple",
      subtitle3: " and ",
      subtitle4Bold: "fast ",
      subtitle5: "way ",
      card1Title: "ISEE",
      card1Description:
        "Apply for your yearly ISEE certificate online and quickly.",
      card2Title: "730",
      card2Description:
        "It is possible to fill the tax return form 730, find out about benefits and increase your tax saving.",
      card3Title: "Public bonuses",
      card3Description:
        "Bonuses and services you need can be claimed online, with no queues and supported by qualified professionals.",
    },
    numbers: {
      title: "When it comes to bureaucracy, numbers matter",
      subtitle:
        "These are some of the achievements of BonusX in September 2022",
      card1Description: "Users",
      card2Description: "In supplied bonuses",
      card3Description: "Monthly visitors",
    },
    reviews: {
      title: "About BonusX",
      button: "Read on Trustpilot",
      card1Review:
        "Simple and easy to use website, I applied for the bonus in less than 5 minutes. It is a lifesaver for those like me who cannot go on-site to the offices",
      card2Review:
        "It is not the first time using this service and even this time I'd like to confirm my full satisfaction. The practice was finalized quickly and smoothly, completely online and with no waste of time. Thank you so much, BonusX, great job",
      card3Review:
        "As excellent as if I had gone to the office, or even better",
    },
    frontend: {
      title1: " ",
      title2Bold: "Frontend",
      title3: "Develop",
      title3alt: "Development",
      title4: "ment",
      description:
        "We developed a React platform to be useful, simple and straightforward, available both from web and from mobile.",
    },
    backend: {
      title1: " ",
      title2Bold: "Backend ",
      title3: "Development",
      description:
        "BonusX algorithm suggests the most fitting bonuses for its users: filling in the guided form, each user is advised on how to make the most of their bonuses! We built a solid infastructure with Node.js in Typescript, which allows to handle hundreds of practices every day and to establish contact with the government office for tax assistance throughout Italy.",
    },
  },
};

export const l_bonusx = (
  trad: DeepKeyof<typeof traduction[keyof typeof traduction]>
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
