/** @jsxImportSource @emotion/react */

import { Box } from "@mui/system";
import Description from "./Description";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const BigBlueSection = ({ isOverMd }: { isOverMd: boolean }) => {
  return (
    <MabiParallax
      id="frontendSect"
      duration={1}
      tweens={[
        // {
        //   selector: ".frontendExampleImage",
        //   from: {
        //     y: 200,
        //     opacity: 0.8,
        //   },
        //   to: {
        //     y: 0,
        //     opacity: 1,
        //   },
        // },
        {
          selector: ".frontendGreenSphere",
          from: {
            x: 0,
            y: -60,
          },
          to: {
            x: 100,
            y: 0,
          },
        },
        {
          selector: ".frontendMagentaSphere",
          from: {
            x: 80,
            y: -100,
          },
          to: {
            x: 0,
            y: 100,
          },
        },
        {
          selector: ".frontendModulesIllustration",
          from: {
            x: -400,
            y: 300,
            rotate: "0deg",
          },
          to: {
            x: 0,
            y: -30,
            rotate: "-30deg",
          },
        },
      ]}
    >
      <Box
        sx={{
          flex: 1,
          transform: {
            md: "translateX(-50px) translateY(80px)",
            xs: "translateX(0px)",
          },
          position: "relative",
          display: { md: "block", xs: "flex" },
          justifyContent: "flex-end",
        }}
      >
        <StaticImage
          alt=""
          src={"../../images/bigBlueDecoration.svg"}
          imgStyle={{
            height: "auto",
            // overflow: "visible"
          }}
          css={{
            // overflow: "visible",
            "> div": { maxWidth: "unset !important" },
            width: isOverMd ? "calc(100% + 50px)" : "80%",
          }}
        />

        <Box sx={{ display: { md: "block", xs: "none" } }}>
          <Description />
        </Box>

        <Box
          sx={{
            position: "absolute",
            left: { xs: "-24px", md: "-120px" },
            bottom: "-100px",
            width: { md: "105%", xs: "100%" },
            top: { xs: "25%", md: "unset" },
          }}
        >
          <div className="frontendExampleImage" css={{ zIndex: 3 }}>
            <StaticImage
              src={"../../images/exampleImage.png"}
              imgStyle={{ zIndex: 3 }}
              alt={""}
            />
          </div>

          <Box
            sx={{
              position: "absolute",
              transform: "scale(0.9)",
              top: "-45px",
              left: "35%",
              display: { md: "block", xs: "none" },
            }}
            className="frontendGreenSphere"
          >
            <StaticImage src={"../../images/greenSphere.svg"} alt={""} />
          </Box>
          <Box
            sx={{
              position: "absolute",
              transform: "scale(0.8)",
              top: "32%",
              right: "-45px",
              display: { md: "block", xs: "none" },
            }}
            className="frontendMagentaSphere"
          >
            <StaticImage src={"../../images/magentaSphere.svg"} alt={""} />
          </Box>

          <MabiParallax id="frontendSectModules" duration={0.5} tweens={[]}>
            <Box
              sx={{
                position: "absolute",
                top: { md: "-75px", xs: "-25%" },
                right: { md: "-60px", xs: "-20px" },
                transform: { md: "scale(1)", xs: "scale(0.7)" },
                zIndex: 2,
              }}
              className="frontendModulesIllustration"
            >
              <StaticImage
                alt=""
                src={"../../images/modulesIllustration_3.png"}
                imgStyle={{ zIndex: 2 }}
              />
            </Box>
          </MabiParallax>
        </Box>
      </Box>
    </MabiParallax>
  );
};

export default BigBlueSection;
