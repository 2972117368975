/** @jsxImportSource @emotion/react */

import { Card } from "./CardsSection";
import { StaticImage } from "gatsby-plugin-image";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { l_bonusx } from "../../locales";

const BlueCard = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        position: "relative",
        width: { md: "fit-content", xs: "fit-content" },
        minWidth: { xs: "100%", sm: "548px", md: "unset" },
        boxSizing: { md: "content-box", xs: "border-box" },
        margin: { xs: "auto", md: "unset" },
        marginLeft: { md: "auto" },
        justifySelf: { md: "flex-end" },
      }}
      className={"reviewsBlueCard"}
      // className={isOverMd ? "reviewsBlueCard" : ""}
    >
      <Card
        reviewText={l_bonusx("reviews.card3Review")}
        reviewAuthor="Massimiliano"
        cardStyle={{
          boxSizing: "content-box",
          maxWidth: { md: "350px", xs: "500px" },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          zIndex: 4,
          top: { md: 0, xs: "auto" },
          left: { md: 0, xs: "auto" },
          right: { md: "auto", xs: 20 },
          bottom: { md: "auto", xs: 10 },
          transform: {
            md: "translate(-90%, 10%)",
            xs: "",
          },
        }}
      >
        <StaticImage
          src={"../../images/reviewPicBlue.png"}
          alt=""
          css={{ width: isOverMd ? "auto" : "56px" }}
        />
      </Box>
    </Box>
  );
};

export default BlueCard;
