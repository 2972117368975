/** @jsxImportSource @emotion/react */

import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const CentralPictureTopLeft = () => {
  return (
    <MabiParallax
      id="backendSectTopLeft"
      tweens={[
        {
          selector: ".backendModules1",
          from: {
            x: -30,
            y: -30,
          },
          to: {
            x: 30,
            y: 50,
          },
        },
      ]}
    >
      <div
        css={{
          position: "absolute",
          top: 0,
          left: "-24px",
          width: "17%",
          aspectRatio: "1/1",
          borderRadius: "22px",
          backgroundColor: "#FF14721A",
        }}
        className="backendModules1"
      >
        <div
          css={{
            position: "absolute",
            top: "10px",
            left: "10px",
            zIndex: 2,
          }}
        >
          <StaticImage
            src={"../../images/modulesIllustration_1.png"}
            alt=""
            css={{ top: "20px", left: "20px" }}
          />
        </div>
      </div>
    </MabiParallax>
  );
};

export default CentralPictureTopLeft;
