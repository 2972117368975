/** @jsxImportSource @emotion/react */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const ShapedBackground = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        width: "100%",
        height: "100%",
        bottom: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        zIndex: 1,
        marginBottom: { xs: "180px", md: "100px" },
      }}
    >
      {isOverMd ? (
        <StaticImage
          src="../../images/backgroundShapedBig.svg"
          alt=""
          imgStyle={{ height: "auto", top: "unset" }}
          css={{ width: "102%" }}
        />
      ) : (
        <StaticImage
          src="../../images/backgroundShapedSmall.svg"
          alt=""
          imgStyle={{ height: "auto", top: "unset" }}
          css={{ width: "102%" }}
        />
      )}
    </Box>
  );
};

export default ShapedBackground;
