/** @jsxImportSource @emotion/react */

import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Title = () => {
  return (
    <div css={{ textAlign: "center", marginBottom: "20px" }}>
      <BonusxTypography variant="title">
        {l_bonusx("backend.title1")}
        <BonusxTypography variant="span" css={{ color: "#FF1472" }}>
          {l_bonusx("backend.title2Bold")}
        </BonusxTypography>
        {l_bonusx("backend.title3")}
      </BonusxTypography>
    </div>
  );
};

export default Title;
