/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import BigBlueSection from "./FrontendDevSection/BigBlueSection";
import Description from "./FrontendDevSection/Description";
import Title from "./FrontendDevSection/Title";

const FrontendDevSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "1500px",
        margin: "auto",
        padding: "0px 24px",
        paddingBottom: { md: "280px", sm: "380px", xs: "380px" },
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
      }}
    >
      <Title />
      <Box sx={{ display: { md: "none", xs: "box" } }}>
        <Description />
      </Box>
      <BigBlueSection isOverMd={isOverMd} />
    </Box>
  );
};
export default FrontendDevSection;
