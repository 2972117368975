/** @jsxImportSource @emotion/react */

import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const CentralPictureTopRight = () => {
  return (
    <MabiParallax
      id="backendSectTopRight"
      tweens={[
        {
          selector: ".backendMagentaSphere",
          from: {
            y: -100,
            x: 0,
            scale: 0.7,
          },
          to: {
            y: 0,
            x: -30,
            scale: 0.6,
          },
        },
      ]}
    >
      <div
        css={{
          position: "absolute",
          top: "-10%",
          right: "-20%",
          zIndex: 2,
        }}
      >
        <StaticImage
          src={"../../images/exampleBackend_2.png"}
          css={{ width: "100%" }}
          alt={""}
        />
        <div
          css={{
            position: "absolute",
            zIndex: 2,
            top: "30%",
            left: "-20%",
            transform: "scale(0.7)",
          }}
          className="backendMagentaSphere"
        >
          <StaticImage src={"../../images/magentaSphere.svg"} alt="" css={{}} />
        </div>
      </div>
    </MabiParallax>
  );
};

export default CentralPictureTopRight;
