/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";

const ConcentricCircles = ({
  nCircles = { md: 20, xs: 10 },
  gap = { md: 50, xs: 30 },
  className,
}: {
  nCircles?: { md: number; xs: number };
  gap?: { md: number; xs: number };
  className?: string;
}) => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const num = [...Array(isOverMd ? nCircles.md : nCircles?.xs).keys()];

  return (
    <Box
      css={{
        border: "1px solid#D1CDDE66",
        borderRadius: "50%",
        aspectRatio: "1/1",
        position: "relative",
        zIndex: 1,
      }}
      className={className}
    >
      {num.map((elem, ind) => {
        return (
          <Box
            key={ind}
            sx={{
              width: `calc(100% - ${(elem + 1) *
                (isOverMd ? gap.md : gap.xs)}px)`,
              // width: `100%`,
              borderRadius: "50%",
              border: "1px solid#D1CDDE66",
              aspectRatio: "1/1",
              position: "absolute",
              zIndex: 1,
              // top: `${(elem + 1) * (gap/2)}px`,
              // left: `${(elem + 1) * (gap/2)}px`,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></Box>
        );
      })}
    </Box>
  );
};

export default ConcentricCircles;
