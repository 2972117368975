/** @jsxImportSource @emotion/react */

import BonusxTypography from "../Theme/BonusxTypography";
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_bonusx } from "../../locales";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const Title = () => {
  return (
    <MabiParallax
      id="numberSectDecorations"
      duration={1}
      tweens={[
        {
          selector: ".numberDeco1",
          from: {
            y: "-400%",
            x: -60,
          },
          to: {
            y: "-200%",
            x: 0,
          },
        },
        {
          selector: ".numberDeco2",
          from: {
            y: 200,
            x: 80,
            rotate: "-90deg",
          },
          to: {
            y: 0,
            x: 0,
            rotate: "0deg",
          },
        },
      ]}
    >
      <Box
        sx={{
          textAlign: "center",
          position: "relative",
          maxWidth: "900px",
          margin: "auto",
          padding: { sm: "24px", md: "12px" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            transform: "translateY(-200%)",
            display: { xs: "none", md: "block" },
            // ...ress("sm", { display: "none" }),
          }}
          className="numberDeco1"
        >
          <StaticImage src="../../images/greenSphere.svg" alt="" />
        </Box>
        <BonusxTypography variant="title" css={{}}>
          {l_bonusx("numbers.title")}
        </BonusxTypography>
        <BonusxTypography variant="subtitle" css={{ marginTop: "30px" }}>
          {l_bonusx("numbers.subtitle")}
        </BonusxTypography>
        <Box
          sx={{
            position: "absolute",
            bottom: -40,
            right: 0,
            display: { xs: "none", md: "block" },

            // ...ress("sm", { display: "none" }),
          }}
          className="numberDeco2"
        >
          <StaticImage src="../../images/magentaSphere.svg" alt="" />
        </Box>
      </Box>
    </MabiParallax>
  );
};

export default Title;
