/** @jsxImportSource @emotion/react */

import {
  Box,
  Stack,
  stepClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";
import BackgroundPictureSmall from "./BackendDevSection/BackgroundPictureSmall";
import CentralPicturesSection from "./BackendDevSection/CentralPicturesSection";
import Description from "./BackendDevSection/Description";
import Title from "./BackendDevSection/Title";
import ConcentricCircles from "./ConcentricCircles";

const BackendDevSection = () => {
  return (
    <MabiParallax
      id="backendSmall"
      duration={1.5}
      tweens={[
        {
          selector: ".backendCirclesSmall",
          from: {
            scale: 1.2,
          },
          to: { scale: 1 },
        },
        {
          selector: ".backendSmallModules1",
          from: {
            scale: 1,
            x: -20,
            y: -80,
            rotate: "10deg",
          },
          to: { scale: 0.8, rotate: 0, x: 10, y: 0 },
        },
        {
          selector: ".backendSmallModules5",
          from: {
            scale: 1,
            x: -100,
            y: 100,
            rotate: "-20deg",
          },
          to: { scale: 0.8, rotate: 0, x: 0, y: 0 },
        },
        {
          selector: ".backendSpheresGroup",
          from: { x: 50, y: 200 },
          to: { x: 0, y: 0 },
        },
      ]}
    >
      <Box
        sx={{
          maxWidth: "1500px",
          margin: "auto",
          marginBottom: { md: "300px", sm: "350px", xs: "280px" },
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Stack
          sx={{
            margin: "24px",
            position: "relative",
            display: { xs: "flex", md: "block" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title />
          <Description />
          <CentralPicturesSection />
          <BackgroundPictureSmall />
        </Stack>
      </Box>
    </MabiParallax>
  );
};
export default BackendDevSection;
