/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_bonusx } from "../../locales";
import { Card } from "./CardsSection";

const MagentaCard = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      sx={{
        position: "relative",
        width: "fit-content",
        minWidth: { xs: "100%", sm: "500px", md: "unset" },
        alignSelf: { md: "center", xs: "center" },
        margin: "auto",
        boxSizing: { md: "content-box", xs: "border-box" },
      }}
      className={"reviewsMagentaCard"}
      // className={isOverMd ? "reviewsMagentaCard" : ""}
    >
      <Card
        reviewText={l_bonusx("reviews.card1Review")}
        reviewAuthor="Francesca"
        cardStyle={{
          boxSizing: "content-box",
          maxWidth: { md: "400px", xs: "500px" },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          zIndex: 4,
          right: { md: 0, xs: 20 },
          bottom: { md: 0, xs: 10 },
          transform: { md: "translate(60%, 40%)", xs: "" },
        }}
      >
        <StaticImage
          src={"../../images/reviewPicMagenta.png"}
          alt=""
          css={{ width: isOverMd ? "auto" : "56px" }}
        />
      </Box>
    </Box>
  );
};

export default MagentaCard;
