/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { Box, SxProps, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import BonusxTypography from "../Theme/BonusxTypography";
import BlueCard from "./BlueCard";
import CirclesSection from "./CirclesSection";
import GreenCard from "./GreenCard";
import MagentaCard from "./MagentaCard";
// import { DrawSVGPlugin } from "gsap";

export const Card = ({
  reviewText,
  reviewAuthor,
  cardStyle,
  showIcon,
}: {
  reviewText: string;
  reviewAuthor: string;
  cardStyle: SxProps;
  showIcon?: boolean;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "32px",
        boxShadow: "5px 10px 48px rgba(0, 0, 0, 0.04)",
        position: "relative",
        zIndex: 3,
        maxWidth: "500px",
        ...cardStyle,
      }}
    >
      <BonusxTypography variant="description" css={{ marginBottom: "36px" }}>
        {reviewText}{" "}
        {showIcon && (
          <BonusxTypography variant="span">
            <StaticImage alt="" src="../../images/thumbIcon.png" />
          </BonusxTypography>
        )}
      </BonusxTypography>

      <BonusxTypography variant="span" css={{ fontWeight: 700 }}>
        {reviewAuthor}
      </BonusxTypography>
    </Box>
  );
};

const CardsSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const isOverLg = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box
      sx={{
        // border: "1px solid red",
        width: { xs: "100%", md: "70%" },
        boxSizing: "border-box",
        maxWidth: "800px",
        padding: "0px 16px",
        marginLeft: "auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        gap: { xs: "30px", md: "60px" },
        position: "relative",
        transform: { md: "translate(-50px, 100px)", xs: "" },
        zIndex: 1,
      }}
    >
      {isOverMd && (
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <CirclesSection />
        </Box>
      )}

      <MabiParallax
        id="reviewsCardsMagenta"
        tweens={[
          {
            selector: ".reviewsMagentaCard",
            from: {
              x: 80,
              y: 100,
            },
            to: {
              x: 0,
              y: 0,
            },
          },
        ]}
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <MagentaCard />
      </MabiParallax>

      <MabiParallax
        id="reviewsCardsGreen"
        tweens={[
          {
            selector: ".reviewsGreenCard",
            from: {
              x: -80,
              y: 100,
            },
            to: {
              x: 0,
              y: 0,
            },
          },
        ]}
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <GreenCard />
      </MabiParallax>

      <MabiParallax
        id="reviewsCardsBlue"
        tweens={[
          {
            selector: ".reviewsBlueCard",
            from: {
              x: 80,
              y: 100,
            },
            to: {
              x: isOverLg ? "-20%" : isOverMd ? "-10%" : 0,
              y: 0,
            },
          },
        ]}
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <BlueCard />
      </MabiParallax>
    </Box>
  );
};

export default CardsSection;

{
  /* <MabiParallax
        id="lineaProg5"
        duration={3}
        tweens={[
          {
            selector: ".linea",
            from: {
              drawSVG: "0%",
            },
            to: {
              drawSVG: "100%",
              immediateRender: false,
            },
          },
          {
            selector: ".linea2",
            from: {
              width: 0,
              backgroundColor: "red",
            },
            to: {
              width: 500,
              backgroundColor: "blue",
            },
          },
        ]}
      >
        <div
          css={{
            height: 500,
            position: "relative",
            border: "1px solid yellow",
          }}
        >
          <svg width={500} height={100} viewBox="0 0 500 100">
            <path
              className="linea"
              d="M0 0 L450 0"
              fill="none"
              stroke="red"
              strokeWidth={10}
              // style={{ strokeDasharray: 450, strokeDashoffset: 350 }}
            />
          </svg>
          <div
            css={{ width: 500, height: 10, backgroundColor: "yellow" }}
            className="linea2"
          />
        </div>
      </MabiParallax> */
}
