/** @jsxImportSource @emotion/react */
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const CentralPictureBottomLeft = () => {
  return (
    <MabiParallax
      id="backendBottomLeft"
      tweens={[
        {
          selector: ".backendGreenSphere",
          from: {
            x: -100,
            y: 30,
          },
          to: {
            x: 0,
            y: 0,
          },
        },
      ]}
    >
      <div
        css={{
          position: "absolute",
          zIndex: 2,
          bottom: "20%",
          left: "-22%",
        }}
      >
        <StaticImage
          src={"../../images/exampleBackend_1.png"}
          css={{}}
          alt=""
        />
        <div
          css={{
            position: "absolute",
            zIndex: 2,
            top: "-20%",
            right: "-12%",
            // ...ress("sm", { maxWidth: "200px", top: "-10%", right: "-40%" }),
          }}
        >
          <StaticImage
            src={"../../images/modulesIllustration_4.png"}
            css={{}}
            alt=""
          />
        </div>
        <div
          css={{
            position: "absolute",
            zIndex: 2,
            bottom: "-20%",
            right: "-12%",
            transform: "scale(0.7)",
          }}
          className="backendGreenSphere"
        >
          <StaticImage src={"../../images/greenSphere.svg"} css={{}} alt="" />
        </div>
      </div>
    </MabiParallax>
  );
};

export default CentralPictureBottomLeft;
