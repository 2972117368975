/** @jsxImportSource @emotion/react */
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";
import BlueCardIcon from "./BlueCardIcon";
import GreenCardIcon from "./GreenCardIcon";
import WhiteCardIcon from "./WhiteCardIcon";

const Card = ({
  title,
  description,
  color,
  icon,
  className,
}: {
  title?: string;
  description?: string;
  color?: string;
  icon?: any;
  className?: string;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        color: color === "white" ? "black" : "white",
        boxShadow: "0px 10px 200px rgba(147, 145, 169, 0.2)",
        boxSizing: "border-box",
        borderRadius: "20px",
        padding: "36px 12px",
        textAlign: "center",
        // maxWidth: "500px",
        maxWidth: { xs: "500px", md: "unset" },
        margin: { xs: "auto", md: 0 },
        marginBottom: { xs: "30px", md: 0 },
      }}
      className={className}
    >
      <Typography
        noWrap
        sx={{
          fontSize: { md: "48px", xs: "32px" },
          lineHeight: "50px",
          fontWeight: 600,
          marginBottom: "12px",
        }}
      >
        {title}
      </Typography>
      <BonusxTypography
        noWrap
        variant="description"
        sx={{
          color: "inherit",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "16px",
          fontSize: "24px",
          fontHeight: "25px",
        }}
      >
        <span css={{ marginBottom: "-2px" }}>{icon}</span>
        {description}
      </BonusxTypography>
    </Box>
  );
};

const CardsSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="numberSectionCards"
      duration={0.7}
      tweens={
        isOverMd
          ? [
              {
                selector: ".numbersGreenCard",
                from: {
                  y: 400,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
              {
                selector: ".numbersBlueCard",
                from: {
                  y: 200,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
              {
                selector: ".numbersWhiteCard",
                from: {
                  y: 200,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
            ]
          : [
              {
                selector: ".numbersGreenCard",
                from: {
                  y: 600,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
              {
                selector: ".numbersWhiteCard",
                from: {
                  y: 400,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
              {
                selector: ".numbersBlueCard",
                from: {
                  y: 0,
                  opacity: 0.5,
                },
                to: { y: 0, opacity: 1 },
              },
            ]
      }
    >
      <Box
        sx={{
          margin: "auto",
          marginTop: { xs: "40px", md: "64px" },
          marginBottom: { xs: "120px", md: "280px" },
          padding: "0px 24px",
          maxWidth: "1000px",
          textOverflow: "ellipse",
          display: { xs: "block", md: "flex" },
          gap: "32px",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <Box
          sx={{
            margin: "auto",
            flex: 0.8,
          }}
        >
          <Card
            title="+100.000"
            description={l_bonusx("numbers.card1Description")}
            icon={<GreenCardIcon />}
            color="#00DA81"
            className="numbersGreenCard"
          />
        </Box>
        <Box
          sx={{
            gap: "38px",
            display: { xs: "block", md: "flex" },
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Card
            icon={<WhiteCardIcon />}
            color="white"
            title="+10.000.000 euro"
            description={l_bonusx("numbers.card2Description")}
            className="numbersWhiteCard"
          />
          <Card
            icon={<BlueCardIcon />}
            color="#1A59E7"
            title="+450.000"
            description={l_bonusx("numbers.card3Description")}
            className="numbersBlueCard"
          />
        </Box>
      </Box>
    </MabiParallax>
  );
};

export default CardsSection;
