/** @jsxImportSource @emotion/react */
import Footer from "../../../components/shared/Footer/Footer";
import PrevNextCaseStudy from "../../../components/shared/PrevNextCaseStudy/PrevNextCaseStudy";
import { PageProps } from "../../../components/utils/PageProps";
import BackendDevSection from "./components/BackendDevSection";
import EasyBurocracySection from "./components/EasyBurocracySection";
import BonusXFooter from "./components/Footer";
import FrontendDevSection from "./components/FrontendDevSection";
import Header from "./components/Header";
import NumbersCountSection from "./components/NumbersCountSection";
import ReviewsSection from "./components/ReviewsSection";

const BonusX = (props: PageProps) => {
  return (
    <>
      {/* <img src={require("./images/sgeeks_4.png")} />
        <div css={{ width: 200, height: 100 }}> */}
      {/* <Image filename="casestudy/bonusx/a_sgeeks_4.png" /> */}

      {/* <div
        css={{
          padding: 100,
          background: "red",

          ...ress("sm+", { background: "orange" }),
        }}
      /> */}

      {/* <BodyPadding> */}
      <Header />
      <EasyBurocracySection />
      <NumbersCountSection />
      <ReviewsSection />
      <FrontendDevSection />
      <BackendDevSection />
      <BonusXFooter />
      <PrevNextCaseStudy />
      {/* </BodyPadding> */}
      <Footer />
    </>
  );
};

export default BonusX;
