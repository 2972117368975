/** @jsxImportSource @emotion/react */

import ConcentricCircles from "../ConcentricCircles";
import CentralPictureBottomLeft from "./CentralPictureBottomLeft";
import CentralPictureTopLeft from "./CentralPictureTopLeft";
import CentralPictureTopRight from "./CentralPictureTopRight";
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import CentralPictureBottomRight from "./CentralPictureBottomRight";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

const CentralPicturesSection = () => {
  return (
    <Box
      sx={{
        display: { md: "flex", xs: "none" },
        justifyContent: "center",
        alignItems: "center",
        maxWidth: { md: "900px", xs: "250px" },
        margin: "auto",
        marginTop: "120px",
        position: "relative",
      }}
    >
      <div
        css={{
          padding: "25%",
          position: "relative",
        }}
      >
        <MabiParallax
          id="backendSectImages"
          duration={1}
          tweens={[
            {
              selector: ".backendCircles",
              from: {
                scale: 1.4,
              },
              to: {
                scale: 1,
              },
            },
            {
              selector: ".backendModules5",
              from: {
                x: -200,
                y: 200,
                scale: 1.2,
              },
              to: {
                x: 0,
                y: 0,
                scale: 1,
              },
            },
            {
              selector: ".backendBlueSphere",
              from: {
                x: 0,
              },
              to: {
                x: 100,
              },
            },
          ]}
        >
          <CentralPictureTopLeft />

          <Box
            sx={{
              position: "absolute",
              top: { md: "0px", xs: "-100px" },
              left: { md: "20%", xs: "-10px" },
            }}
            className="backendBlueSphere"
          >
            <StaticImage src={"../../images/blueSphere.svg"} alt={""} />
          </Box>

          <CentralPictureTopRight />

          <StaticImage
            src="../../images/avatarWithProgress.png"
            alt={""}
            css={{ marginTop: "3px" }}
          />

          {/* <div
            css={{
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
            }}
          >
            <ConcentricCircles
              nCircles={{ md: 2, xs: 0 }}
              gap={{ md: -60, xs: 0 }}
            />
          </div> */}
          <div
            css={{
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
            }}
            className="backendCircles"
          >
            <ConcentricCircles
              nCircles={{ md: 8, xs: 0 }}
              gap={{ md: 60, xs: 0 }}
            />
          </div>

          <CentralPictureBottomLeft />

          <div
            css={{
              position: "absolute",
              zIndex: 2,
              bottom: "-2%",
              left: "20%",
            }}
            className="backendModules5"
          >
            <StaticImage
              src="../../images/modulesIllustration_5.png"
              css={{ width: "100%" }}
              alt={""}
            />
          </div>

          <CentralPictureBottomRight />
        </MabiParallax>
      </div>
    </Box>
  );
};

export default CentralPicturesSection;
