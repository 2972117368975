/** @jsxImportSource @emotion/react */

import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../components/MabiloftSmoothScrollInterpolator";
import { l_bonusx } from "../locales";
import CardsSection from "./ReviewSection/CardsSection";
import CirclesSection from "./ReviewSection/CirclesSection";
import Title from "./ReviewSection/Title";

const ReviewsSection = () => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: { xs: "100%", md: "100%" },
        maxWidth: { xs: "1500px", lg: "1500px" },
        margin: "auto",
        marginBottom: { md: "380px", xs: "60px" },
        display: "flex",
        gap: "-100px",
        flexDirection: { md: "row", xs: "column" },
        position: "relative",
      }}
    >
      <Title />

      <CardsSection />

      <Button
        variant="contained"
        href="https://it.trustpilot.com/review/bonusx.it"
        target={"_blank"}
        sx={{
          display: { md: "none", xs: "block" },
          alignSelf: "center",
          marginTop: "60px",
          position: "relative",
          zIndex: 2,
          isolation: "isolate",
          backgroundColor: "#1A59E7",
          color: "#fff",
          textTransform: "none",
          padding: "12px 52px",
          borderRadius: "12px",
          ":hover": {
            backgroundColor: "#2666F5",
          },
          ":active": { backgroundColor: "#1A59E7" },
        }}
      >
        {l_bonusx("reviews.button")}
      </Button>
    </Box>
  );
};
export default ReviewsSection;
