/** @jsxImportSource @emotion/react */

import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";
import { l_bonusx } from "../../locales";
import BlueCardIcon from "./BlueCardIcon";
import GreenCardIcon from "./GreenCardIcon";
import MagentaCardIcon from "./MagentaCardIcon";

type CardProps = {
  title: string;
  subtitle: string;
  type: "blue" | "green" | "magenta";
  icon: any;
  className?: string;
};

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  type,
  icon,
  className,
}) => {
  return (
    <>
      <Box
        className={className}
        sx={{
          flex: type === "blue" ? 0.8 : 1,
          padding: { xs: "32px 40px", xl: "48px 60px" },
          backgroundColor: "white",
          textAlign: "left",
          maxWidth:
            type === "blue"
              ? { xs: "240px", xl: "360px" }
              : { xs: "320px", xl: "400px" },
          marginBottom: { xs: "50px" },
          transform: type === "blue" ? { md: "translateY(-50%)", xs: "" } : "",
          borderRadius: "18px",
          margin: "10px",
          boxShadow: "0px 1px 18px rgba(0, 0, 0, 0.12)",
          position: "relative",
          zIndex: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            lineHeight: "40px",
            marginBottom: "16px",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          {subtitle}
        </Typography>
        <Box
          sx={{
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(type === "green"
              ? {
                  backgroundColor: "#00DA81",
                  right: 0,
                  top: 0,
                  transform: "translate(-25px, -35px)",
                }
              : type === "magenta"
              ? {
                  top: { xs: "auto", md: 0 },
                  right: { xs: "auto", md: "auto" },
                  bottom: { xs: 0, md: "auto" },
                  left: { xs: 0, md: 0 },
                  transform: {
                    md: "translate(25px, -40px)",
                    xs: "translate(20px, +45px)",
                  },
                  backgroundColor: "#FF1472",
                }
              : {
                  backgroundColor: "#1A59E7",
                  bottom: 0,
                  right: 0,
                  transform: "translate(-20px, +45px)",
                }),
          }}
        >
          {icon}
        </Box>
      </Box>
    </>
  );
};

const CardsSection = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box sx={{ zIndex: 2 }}>
      <MabiParallax
        id="burocracySectCards"
        duration={1}
        tweens={
          isOverMd
            ? [
                {
                  selector: ".burocracyGreenCard",
                  from: {
                    y: 100,
                    opacity: 0.5,
                  },
                  to: {
                    y: 0,
                    opacity: 1,
                  },
                },
                {
                  selector: ".burocracyBlueCard",
                  from: {
                    y: 200,
                    opacity: 0.5,
                  },
                  to: {
                    y: 0,
                    opacity: 1,
                  },
                },
                {
                  selector: ".burocracyMagentaCard",
                  from: {
                    y: 100,
                    opacity: 0.5,
                  },
                  to: {
                    y: 0,
                    opacity: 1,
                  },
                },
              ]
            : []
        }
      >
        <Stack
          sx={{
            position: "relative",
            zIndex: 2,
            width: "100%",
            maxWidth: "1900px",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            margin: "auto",
            marginTop: { xs: "80px", md: 0 },
            marginBottom: { xs: "120px", md: 0 },
          }}
        >
          <Card
            type="green"
            title={l_bonusx("burocracy.card1Title")}
            subtitle={l_bonusx("burocracy.card1Description")}
            icon={<GreenCardIcon />}
            className={"burocracyGreenCard"}
          />
          <Card
            type="blue"
            title={l_bonusx("burocracy.card2Title")}
            subtitle={l_bonusx("burocracy.card2Description")}
            icon={<BlueCardIcon />}
            className={"burocracyBlueCard"}
          />
          <Card
            type="magenta"
            title={l_bonusx("burocracy.card3Title")}
            subtitle={l_bonusx("burocracy.card3Description")}
            icon={<MagentaCardIcon />}
            className={"burocracyMagentaCard"}
          />
        </Stack>
      </MabiParallax>
    </Box>
  );
};

export default CardsSection;
