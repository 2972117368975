function GreenCardIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__greencard_b"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={1}
        y={4}
        width={22}
        height={17}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.887 12.146a.75.75 0 01-.103-1.493 2.133 2.133 0 001.827-2.1 2.115 2.115 0 00-1.78-2.093.75.75 0 01.245-1.48 3.611 3.611 0 013.035 3.576 3.642 3.642 0 01-3.12 3.584.737.737 0 01-.104.006"
          fill="#fff"
        />
        <mask
          id="prefix__greencard_a"
          style={{
            maskType: "alpha",
          }}
          maskUnits="userSpaceOnUse"
          x={18}
          y={14}
          width={5}
          height={5}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.978 14.002h3.794v4.53h-3.794v-4.53z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#prefix__greencard_a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.861 18.531a.75.75 0 01-.267-1.45c.678-.259.678-.544.678-.68 0-.439-.557-.745-1.655-.909a.752.752 0 01-.63-.854.755.755 0 01.853-.63c2.423.363 2.932 1.501 2.932 2.392 0 .544-.214 1.537-1.644 2.082a.759.759 0 01-.267.05"
            fill="#fff"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.886 15.914c-2.376 0-5.209.292-5.209 1.682 0 1.401 2.833 1.695 5.21 1.695 2.375 0 5.207-.29 5.207-1.678 0-1.404-2.832-1.699-5.208-1.699m0 4.877c-1.659 0-6.709 0-6.709-3.195 0-3.182 5.05-3.182 6.71-3.182 1.659 0 6.707 0 6.707 3.2 0 3.177-4.873 3.177-6.708 3.177M11.887 5.5a3.072 3.072 0 00-3.068 3.069c-.003.82.31 1.587.886 2.166.575.579 1.34.9 2.157.903l.025.75v-.75a3.072 3.072 0 003.069-3.069 3.073 3.073 0 00-3.07-3.069m0 7.638h-.026a4.52 4.52 0 01-3.22-1.346A4.518 4.518 0 017.32 8.566 4.573 4.573 0 0111.887 4c2.52 0 4.569 2.05 4.569 4.569a4.574 4.574 0 01-4.57 4.569M5.886 12.146a.737.737 0 01-.104-.007 3.641 3.641 0 01-3.12-3.582A3.612 3.612 0 015.699 4.98a.75.75 0 01.244 1.48 2.116 2.116 0 00-1.78 2.096 2.132 2.132 0 001.827 2.098.75.75 0 01-.103 1.493M2.912 18.531a.759.759 0 01-.267-.049C1.214 17.936 1 16.943 1 16.4c0-.89.509-2.029 2.933-2.392a.755.755 0 01.853.63.752.752 0 01-.631.854c-1.098.164-1.655.47-1.655.908 0 .137 0 .421.679.68a.75.75 0 01-.267 1.451"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__greencard_b)">
        <path fill="#fff" d="M0-.061h24v24H0z" />
      </g>
    </svg>
  );
}

export default GreenCardIcon;
