/** @jsxImportSource @emotion/react */

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { l_bonusx } from "../../locales";
import { Card } from "./CardsSection";

const GreenCard = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        position: "relative",
        width: "fit-content",
        minWidth: { xs: "100%", md: "unset", sm: "500px" },
        alignSelf: { md: "flex-start", xs: "center" },
        // transform: { md: "translateX(-40px)", xs: "" },
        marginRight: { xs: "0px", md: "auto" },
        margin: { xs: "auto", md: "0px" },
        boxSizing: { md: "content-box", xs: "border-box" },
      }}
      className={"reviewsGreenCard"}
    >
      <Card
        reviewText={l_bonusx("reviews.card2Review")}
        reviewAuthor="Elena C."
        showIcon={true}
        cardStyle={{
          boxSizing: "content-box",
          maxWidth: { md: "450px", xs: "500px" },
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: { md: 0, xs: "auto" },
          left: { md: 0, xs: "auto" },
          right: { md: "auto", xs: 20 },
          bottom: { md: "auto", xs: "10px" },
          transform: { xs: "", md: "translate(-90%, 10%)" },
          zIndex: { md: 1, xs: 4 },
        }}
      >
        <StaticImage
          src={"../../images/reviewPicGreen.png"}
          css={{ width: isOverMd ? "auto" : "56px", zIndex: 4 }}
          alt={""}
        />
      </Box>
    </Box>
  );
};

export default GreenCard;
