/** @jsxImportSource @emotion/react */

import { l_bonusx } from "../../locales";
import BonusxTypography from "../Theme/BonusxTypography";

const Title = () => {
  return (
    <>
      <BonusxTypography
        variant={"header"}
        sx={{
          textAlign: "center",
          position: "relative",
          zIndex: 5,
          maxWidth: "1100px",
          margin: "auto",
          padding: { xs: "24px", md: "12px" },
        }}
      >
        {l_bonusx("header.title1")}
        <BonusxTypography color="magenta" variant="span">
          {l_bonusx("header.title2Bold")}
        </BonusxTypography>
        {l_bonusx("header.title3")}

        {/* Abbiamo supportato{" "}
        <BonusxTypography color="magenta" variant="span">
          BonusX
        </BonusxTypography>{" "}
        nella semplificazione della burocrazia Italiana */}
      </BonusxTypography>
    </>
  );
};

export default Title;
