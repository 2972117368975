import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

import ConcentricCircles from "../ConcentricCircles";

const BigCentralImage = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <MabiParallax
      id="headerImage"
      duration={0.3}
      tweens={
        isOverMd
          ? [
              {
                selector: ".headerCircles",
                from: {
                  scale: 1.4,
                  y: 100,
                },
                to: {
                  scale: 1,
                  y: 0,
                },
              },
            ]
          : [
              {
                selector: ".headerCircles",
                from: {
                  scale: 1.4,
                  y: 100,
                },
                to: {
                  scale: 1,
                  y: 0,
                },
              },
            ]
      }
      style={
        {
          // border: "1px solid blue",
        }
      }
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1.56/1",
          position: "relative",
          zIndex: 2,
          maxHeight: "921px",
          maxWidth: "1440px",
          display: "flex",
          // background: "red",
          flexDirection: "column",
          alignItems: "center",
          margin: "auto",
          marginTop: { md: "-100px", xs: "0px" },
        }}
      >
        <MabiParallax
          id="headerImageContainer_bonusX"
          duration={0.3}
          tweens={
            isOverMd
              ? [
                  {
                    selector: ".headerImageContainer",
                    from: {
                      y: 200,
                      opacity: 0.6,
                    },
                    to: {
                      y: 0,
                      opacity: 1,
                    },
                  },
                ]
              : [
                  {
                    selector: ".headerImageContainer",
                    from: {
                      y: 50,
                      opacity: 0.6,
                    },
                    to: {
                      y: 0,
                      opacity: 1,
                    },
                  },
                ]
          }
          style={{ width: "100%", margin: "auto" }}
        >
          <Box
            className={"headerImageContainer"}
            sx={{
              position: { xs: "relative", md: "absolute" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 2,
              paddingTop: "50px",
              overflow: "hidden",
              width: "100%",
              maxHeight: "921px",
              maxWidth: "1440px",
              margin: "auto",
            }}
          >
            <StaticImage src="../../images/headerBonusx.png" alt="" />
          </Box>
        </MabiParallax>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            position: { xs: "absolute", md: "relative" },
            width: "100%",
          }}
        >
          <Box
            className="headerCircles"
            sx={{
              width: "80%",
              maxWidth: "650px",
              position: "relative",
              zIndex: 1,
              top: { xs: 0, md: "-20%" },
            }}
          >
            <ConcentricCircles
              gap={{ md: 60, xs: 50 }}
              nCircles={{ md: 10, xs: 50 }}
            />
          </Box>
        </Box>
      </Box>
    </MabiParallax>
  );
};

export default BigCentralImage;
