/** @jsxImportSource @emotion/react */

import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import MabiParallax from "../../../../../components/MabiloftSmoothScrollInterpolator";

type DecorationSectionProps = {};
const DecorationSection: React.FC<DecorationSectionProps> = ({}) => {
  return (
    <MabiParallax
      id={"buracracySectDecorations"}
      duration={1}
      tweens={[
        {
          selector: ".modulesIllustration1",
          from: {
            x: 0,
            y: 50,
            rotate: "45deg",
          },
          to: {
            x: 100,
            y: 0,
            rotate: "0deg",
          },
        },
        {
          selector: ".modulesIllustration2",
          from: {
            x: 100,
            y: 50,
            rotate: "-50deg",
          },
          to: {
            x: 0,
            y: 0,
            rotate: "0deg",
          },
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          position: { md: "relative", xs: "absolute" },
          zIndex: 2,
          margin: "auto",
          width: { xs: "100%", xl: "90%" },
          height: { md: "180px", lg: "100px" },
        }}
      >
        <Box
          className="modulesIllustration1"
          sx={{
            position: "relative",
            transform: {
              xs: "translate(0px, -10px)",
              md: "translate(40px, 0%)",
            },
          }}
        >
          <StaticImage src="../../images/modulesIllustration_1.png" alt="" />
        </Box>
        <Box
          className="modulesIllustration2"
          sx={{
            position: "relative",
            transform: "translate(15px, 0%)",
            display: { xs: "none", md: "block" },
            width: "200px",
          }}
        >
          <StaticImage src="../../images/modulesIllustration_2.png" alt="" />
        </Box>
      </Box>
    </MabiParallax>
  );
};
export default DecorationSection;
